<template>
  <BaseModalSmall
    v-show="true"
    @close="close"
    v-bind:isValidated="validateForm"
    v-bind:loading="false"
    @confirm="save"
  >
    <template v-slot:header>Communication Preferences</template>
    <template v-slot:body>
      <div class="input-container">
        <select
          v-model="data.do_not_send_account"
          id="do_not_send_account"
          class="nice-select validation"
          required
        >
          <option v-bind:value="true">Yes</option>
          <option v-bind:value="false">No</option>
        </select>
        <label for="do_not_send_account">Disable account communications</label>
      </div>
      <div class="input-container">
        <select
          v-model="data.do_not_send_reminders"
          id="do_not_send_reminders"
          class="nice-select validation"
          required
        >
          <option v-bind:value="true">Yes</option>
          <option v-bind:value="false">No</option>
        </select>
        <label for="current">Disable reminder communications</label>
      </div>
    </template>
    <template v-slot:button>Save</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import { http } from '@/services'

export default {
  name: 'CommunicationModal',
  components: {
    BaseModalSmall
  },
  props: ['communication'],
  computed: {
    validateForm() {
      return true
    }
  },
  watch: {
    communication: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.data.do_not_send_account = value.do_not_send_account
          this.data.do_not_send_reminders = value.do_not_send_reminders
        }
      }
    }
  },
  data() {
    return {
      data: {
        do_not_send_account: null,
        do_not_send_reminders: null
      }
    }
  },
  methods: {
    clearForm() {
      this.data.do_not_send_account = this.communication.do_not_send_account
      this.data.do_not_send_reminders = this.communication.do_not_send_reminders
    },
    save() {
      http
        .patch('/wills/api/communication', this.data)
        .then((response) => {
          this.$store.commit('communication', response.data)
          this.$notification.success('Communication preferences changed')
          this.$emit('close')
        })
        .catch((e) => {
          console.log(e)
        })
    },
    close() {
      this.clearForm()
      this.$emit('close')
    }
  }
}
</script>
