<template>
  <Page
    v-bind:heading="'Account'"
    v-bind:background="'bg-personal-details'"
    v-bind:introClasses="'width600 pb30'"
    v-bind:questionOptions="false"
  >
    <template v-slot:contents>
      <PasswordCard
        @showPasswordModal="show.passwordModal = true"
        @showDeleteModal="show.deleteModal = true"
        @showUserNameModal="show.userNameModal = true"
      ></PasswordCard>
      <CommunicationCard
        :communication="communication"
        @showCommunicationModal="show.communicationModal = true"
      ></CommunicationCard>
      <PasswordModal
        v-show="show.passwordModal"
        @close="show.passwordModal = false"
      ></PasswordModal>
      <CommunicationModal
        :communication="communication"
        v-show="show.communicationModal"
        @close="show.communicationModal = false"
      ></CommunicationModal>
      <DeleteModal
        v-show="show.deleteModal"
        @close="show.deleteModal = false"
        @confirm="deleteAccount"
        :bodyText="'Deleting you account will remove all your Wills and remove all other data that we hold upon you.  Proceed with caution!'"
      ></DeleteModal>
      <UserNameModal
        v-show="show.userNameModal"
        @close="show.userNameModal = false"
      ></UserNameModal>
    </template>
  </Page>
</template>

<script>
import { http } from '@/services'

import Page from '@/common/ui/Page'
import PasswordCard from './PasswordCard'
import CommunicationCard from './CommunicationCard'
import PasswordModal from './PasswordModal'
import CommunicationModal from './CommunicationModal'
import DeleteModal from '@/common/components/DeleteModal'
import UserNameModal from './UserNameModal'

export default {
  name: 'AccountPage',
  components: {
    Page,
    PasswordCard,
    PasswordModal,
    DeleteModal,
    CommunicationCard,
    CommunicationModal,
    UserNameModal
  },
  mounted() {
    this.$store.dispatch('communicationFetch')
  },
  computed: {
    communication() {
      return this.$store.getters.communication
    }
  },
  data() {
    return {
      show: {
        passwordModal: false,
        deleteModal: false,
        communicationModal: false,
        userNameModal: false
      }
    }
  },
  methods: {
    deleteAccount() {
      http
        .post('/wills/api/delete_account', this.data)
        .then((response) => {
          this.$notification.success('Account Deleted')
          this.show.deleteModal = false
          setTimeout((f) => {
            window.location.href = 'https://www.mylastwill.co.uk'
          }, 2000)
        })
        .catch((e) => {
          this.$notification.error(
            'Error deleting account, please contact the help desk'
          )
        })
    }
  }
}
</script>
