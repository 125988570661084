<template>
  <BaseCardLarge
    :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-liability-loan.png'"
  >
    <template v-slot:title>Communication</template>
    <template v-slot:body>
      <p>Control the way we can contact you.</p>
      <p
        v-if="
          communication &&
          communication.do_not_send_account &&
          communication.do_not_send_reminders
        "
        class="font-secondary"
      >
        All communications are disabled
      </p>
      <p
        v-else-if="
          communication &&
          !communication.do_not_send_account &&
          communication.do_not_send_reminders
        "
        class="font-secondary"
      >
        Reminders are disabled
      </p>
      <p
        v-else-if="
          communication &&
          communication.do_not_send_account &&
          !communication.do_not_send_reminders
        "
        class="font-secondary"
      >
        Account communications are disabled
      </p>
      <br />
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
          <div class="input-container">
            <button
              v-on:click="$emit('showCommunicationModal')"
              class="btn btn--primary btn-small"
            >
              Change Preferences
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseCardLarge>
</template>

<script>
import BaseCardLarge from '@/common/ui/BaseCardLarge'

export default {
  name: 'CommunicationCard',
  components: {
    BaseCardLarge
  },
  props: ['communication']
}
</script>
