var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCardLarge',{attrs:{"imageUrl":'https://mylastwill.s3.amazonaws.com/static/img/button-liability-loan.png'},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Communication")]},proxy:true},{key:"body",fn:function(){return [_c('p',[_vm._v("Control the way we can contact you.")]),(
        _vm.communication &&
        _vm.communication.do_not_send_account &&
        _vm.communication.do_not_send_reminders
      )?_c('p',{staticClass:"font-secondary"},[_vm._v(" All communications are disabled ")]):(
        _vm.communication &&
        !_vm.communication.do_not_send_account &&
        _vm.communication.do_not_send_reminders
      )?_c('p',{staticClass:"font-secondary"},[_vm._v(" Reminders are disabled ")]):(
        _vm.communication &&
        _vm.communication.do_not_send_account &&
        !_vm.communication.do_not_send_reminders
      )?_c('p',{staticClass:"font-secondary"},[_vm._v(" Account communications are disabled ")]):_vm._e(),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 col-md-8 col-sm-6 col-xs-12"},[_c('div',{staticClass:"input-container"},[_c('button',{staticClass:"btn btn--primary btn-small",on:{"click":function($event){return _vm.$emit('showCommunicationModal')}}},[_vm._v(" Change Preferences ")])])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }