<template>
  <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
    <div class="input-option selected input-option-big display-flex">
      <div class="image-wrapper left">
        <img v-bind:src="imageUrl" />
      </div>
      <div class="input-option-inner-wrapper">
        <h2 class="pb30">
          <slot name="title"></slot>
        </h2>
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseCardLarge',
  props: ['imageUrl']
}
</script>
