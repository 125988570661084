<template>
  <BaseModalSmall
    v-show="true"
    @close="$emit('close')"
    v-bind:isValidated="validateForm"
    v-bind:loading="false"
    @confirm="save"
  >
    <template v-slot:header>Change Password</template>
    <template v-slot:body>
      <div class="input-container">
        <input
          v-model="data.old_password"
          type="password"
          maxlength="100"
          placeholder="Current Password"
          class="input-standard-grey validation"
          id="current"
          required
        />
        <label for="current">Current Password</label>
        <ErrorPop
          v-show="errors.old_password"
          :message="errors.old_password"
          :displayClose="true"
          @clearError="errors.old_passoword = null"
        ></ErrorPop>
      </div>
      <div class="input-container">
        <input
          v-model="data.new_password"
          type="password"
          maxlength="100"
          placeholder="New Password"
          class="input-standard-grey validation"
          id="new"
          required
        />
        <label for="new">New Password</label>
        <ErrorPop
          v-show="errors.new_password"
          :message="errors.new_password"
          :displayClose="true"
          @clearError="errors.new_password = null"
        ></ErrorPop>
      </div>
      <div class="input-container">
        <input
          v-model="data.repeat_password"
          type="password"
          maxlength="100"
          placeholder="New Password Repeat"
          class="input-standard-grey validation"
          id="repeat"
          required
        />
        <label for="repeat">New Password Repeat</label>
      </div>
    </template>
    <template v-slot:button>Change</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import ErrorPop from '@/common/ui/ErrorPop'
import { http } from '@/services'

export default {
  name: 'PasswordModal',
  components: {
    BaseModalSmall,
    ErrorPop
  },
  computed: {
    validateForm() {
      if (
        this.data.old_password &&
        this.data.new_password &&
        this.data.repeat_password &&
        this.data.repeat_password === this.data.new_password
      )
        return true
      return false
    }
  },
  data() {
    return {
      data: {
        old_password: '',
        new_password: '',
        repeat_password: ''
      },
      errors: {
        new_password: '',
        old_password: ''
      }
    }
  },
  methods: {
    clearForm() {
      this.data.old_password = ''
      this.data.new_password = ''
      this.data.repeat_password = ''
    },
    clearFormErrors() {
      this.errors.new_password = ''
      this.errors.old_password = ''
    },
    save() {
      this.clearFormErrors()
      this.showPasswordProgress = true
      http
        .put('/wills/api/password_change', this.data)
        .then((response) => {
          this.clearForm()
          this.$notification.success('Password changed')
          this.$emit('close')
        })
        .catch((e) => {
          if (e.response.data.new_password) {
            this.errors.new_password = e.response.data.new_password[0]
          }
          if (e.response.data.old_password) {
            this.errors.old_password = e.response.data.old_password[0]
          }
        })
    }
  }
}
</script>

<style></style>
