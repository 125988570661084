<template>
  <BaseCardLarge
    :imageUrl="'https://mylastwill.s3.amazonaws.com/static/img/button-asset-houshold.png'"
  >
    <template v-slot:title>General</template>
    <template v-slot:body>
      <p>
        Deleting your account will remove all information that we hold on you.
        This includes all Wills.
      </p>
      <br />
      <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-6 col-xs-12">
          <div class="input-container">
            <button
              v-on:click="$emit('showPasswordModal')"
              class="btn btn--primary btn-small"
            >
              Change Password
            </button>
          </div>
          <div class="input-container">
            <button
              v-on:click="$emit('showUserNameModal')"
              class="btn btn--primary btn-small"
            >
              Change Username
            </button>
          </div>
          <div class="input-container">
            <button
              v-on:click="$emit('showDeleteModal')"
              class="btn btn--primary btn-small"
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </template>
  </BaseCardLarge>
</template>

<script>
import BaseCardLarge from '@/common/ui/BaseCardLarge'

export default {
  name: 'PasswordCard',
  components: {
    BaseCardLarge
  }
}
</script>

<style></style>
