<template>
  <BaseModalSmall
    v-show="true"
    @close="$emit('close')"
    v-bind:isValidated="validateForm"
    v-bind:loading="false"
    @confirm="save"
  >
    <template v-slot:header>Change Username</template>
    <template v-slot:body>
      <div class="input-container">
        <input
          v-model="data.new_username"
          type="email"
          maxlength="50"
          placeholder="New Username"
          class="input-standard-grey validation"
          id="current"
          required
        />
        <label for="current">New Username</label>
        <ErrorPop
          v-show="errors.new_username"
          :message="errors.new_username"
          :displayClose="true"
          @clearError="errors.new_username = null"
        ></ErrorPop>
      </div>
      <div class="input-container">
        <input
          v-model="data.repeat_username"
          type="email"
          maxlength="50"
          placeholder="Repeat Username"
          class="input-standard-grey validation"
          id="new"
          required
        />
        <label for="new">Repeat Username</label>
      </div>
    </template>
    <template v-slot:button>Change</template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'
import ErrorPop from '@/common/ui/ErrorPop'
import { http } from '@/services'
import { functions } from '@/common/mixins/functions'

export default {
  name: 'UserNameModal',
  mixins: [functions],
  components: {
    BaseModalSmall,
    ErrorPop
  },
  computed: {
    validateForm() {
      if (
        this.validEmail(this.data.new_username) &&
        this.data.repeat_username &&
        this.data.new_username === this.data.repeat_username
      )
        return true
      return false
    }
  },
  data() {
    return {
      data: {
        new_username: '',
        repeat_username: ''
      },
      errors: {
        new_username: ''
      }
    }
  },
  methods: {
    clearForm() {
      this.data.new_username = ''
      this.data.repeat_username = ''
    },
    clearFormErrors() {
      this.errors.new_username = ''
    },
    save() {
      this.clearFormErrors()
      this.showPasswordProgress = true
      http
        .put('/wills/api/username_change', this.data)
        .then((response) => {
          this.clearForm()
          this.$notification.success('Username changed')
          this.$emit('close')
          this.$store.dispatch('userFetch')
        })
        .catch((e) => {
          if (e.response.data.new_username) {
            this.errors.new_username = e.response.data.new_username
          }
        })
    }
  }
}
</script>

<style></style>
